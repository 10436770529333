<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('pos_settings.pos_settings') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_settings.default_client') }}<span class="text-danger">*</span></label>
                <multiselect v-model="client"
                             :multiple="false"
                             :options="clients"
                             :placeholder="$t('pos_settings.default_client')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="fullname"
                             track-by="id">
                </multiselect>
                <span v-if="validation && validation.client_id" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.client_id[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_settings.default_inventory') }}<span class="text-danger">*</span></label>
                <multiselect v-model="inventory"
                             :multiple="false"
                             :options="inventories"
                             :placeholder="$t('pos_settings.default_inventory')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id">
                </multiselect>
                <span v-if="validation && validation.inventory_id"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.inventory_id[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_settings.default_layout') }}</label>
                <select id="client_layout" v-model="data.client_layout" :class="validation && validation.client_layout ? 'is-invalid' : ''" class="custom-select"
                        name="client_layout">
                  <option v-for="row in client_layout_list" :key="row.id" :value="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.client_layout"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.client_layout[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_settings.default_payment') }}</label>
                <select id="default_payment" v-model="data.default_payment" :class="validation && validation.default_payment ? 'is-invalid' : ''" class="custom-select"
                        name="default_payment">
                  <option v-for="row in default_payment_list" :key="row.id" :value="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.default_payment"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.default_payment[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_settings.default_bank') }}</label>
                <div class="input-group">
                  <multiselect v-model="bank"
                               :class="validation && validation.bank_id ? 'is-invalid' : ''"
                               :multiple="false"
                               :options="banks"
                               :placeholder="$t('pos_settings.default_bank')"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               :taggable="false"
                               label="name"
                               track-by="id">
                    <!--                               @search-change="getBanks($event)">-->
                  </multiselect>
                  <div v-if="$can('banks.create')" class="input-group-prepend">
                    <a class="btn btn-primary" href="/finances/banks/create" target="_blank"><i class="fa fa-plus"
                                                                                                style="padding: 0"></i></a>
                  </div>
                </div>
                <!--                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('pos_settings.default_bank') }}.</span>-->
                <span v-if="validation && validation.bank_id" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.bank_id[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_settings.default_cash_treasury') }}</label>
                <div class="input-group">
                  <multiselect v-model="treasury_cash"
                               :class="validation && validation.treasury_cash_id ? 'is-invalid' : ''"
                               :multiple="false"
                               :options="treasuries"
                               :placeholder="$t('pos_settings.default_cash_treasury')"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               :taggable="false"
                               label="name"
                               track-by="id">
                  </multiselect>
                  <div v-if="$can('treasuries.create')" class="input-group-prepend">
                    <a class="btn btn-primary" href="/finances/treasuries/create" target="_blank"><i class="fa fa-plus"
                                                                                                     style="padding: 0"></i></a>
                  </div>
                </div>
                <!--                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('pos_settings.default_cash_treasury') }}.</span>-->
                <span v-if="validation && validation.treasury_id" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.treasury_id[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_settings.default_card_treasury') }}</label>
                <div class="input-group">
                  <multiselect v-model="treasury_card"
                               :class="validation && validation.treasury_card_id ? 'is-invalid' : ''"
                               :multiple="false"
                               :options="treasuries"
                               :placeholder="$t('pos_settings.default_card_treasury')"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               :taggable="false"
                               label="name"
                               track-by="id">
                  </multiselect>
                  <div v-if="$can('treasuries.create')" class="input-group-prepend">
                    <a class="btn btn-primary" href="/finances/treasuries/create" target="_blank"><i class="fa fa-plus"
                                                                                                     style="padding: 0"></i></a>
                  </div>
                </div>
                <!--                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('pos_settings.default_card_treasury') }}.</span>-->
                <span v-if="validation && validation.treasury_card_id"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.treasury_card_id[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_settings.default_currency') }}</label>
                <div class="input-group">
                  <select id="currency_id" v-model="data.currency_id" :class="validation && validation.currency_id ? 'is-invalid' : ''" class="custom-select"
                          name="">
                    <option v-for="row in currencies" :key="row.id" :value="row.id">{{ row.name }}</option>
                  </select>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus"
                                                                                              style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.currency_id[0] }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_settings.enabled_payment') }}</label>
                <select id="enabled_payment" v-model="data.enabled_payment" :class="validation && validation.enabled_payment ? 'is-invalid' : ''" class="custom-select"
                        name="enabled_payment">
                  <option v-for="row in enabled_payment_list" :key="row.id" :value="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.enabled_payment"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.enabled_payment[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_settings.allowed_categories') }}</label>
                <select id="allowed_categories" v-model="data.allowed_categories" :class="validation && validation.allowed_categories ? 'is-invalid' : ''"
                        class="custom-select" name="allowed_categories">
                  <option v-for="row in allowed_categories_list" :key="row.id" :value="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.allowed_categories"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.allowed_categories[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_settings.product_category') }}</label>
                <treeselect
                    :class="validation && validation.category_ids  ? 'is-invalid' : ''"
                    :load-options="loadOptions"
                    :multiple="true"
                    :no-children-text="$t('No_sub_options')"
                    :no-options-text="$t('No_options_available')"
                    :no-results-text="$t('No_results_found')"
                    :options="categories"
                    :placeholder="$t('Select')"
                    :searchable="true"
                    :show-count="true"
                    :value="data.category_ids"
                    @input="updateValue">
                </treeselect>
                <span v-if="validation && validation.category_ids"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.category_ids[0] }}
                </span>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <b-form-checkbox v-model="data.enable_num_pad" name="check-button" size="lg" switch>
                  {{ $t('pos_settings.enable_num_pad') }}
                </b-form-checkbox>
              </div>
              <div class="col-lg-6 mb-5">
                <b-form-checkbox v-model="data.show_product_images" name="check-button" size="lg" switch>
                  {{ $t('pos_settings.show_product_images') }}
                </b-form-checkbox>
              </div>
              <div class="col-lg-6 mb-5">
                <b-form-checkbox v-model="data.accounting_System_per_invoice" name="check-button" size="lg" switch>
                  {{ $t('pos_settings.accounting_System_per_invoice') }}
                </b-form-checkbox>
              </div>
              <div class="col-lg-6 mb-5">
                <b-form-checkbox v-model="data.enable_auto_adjustment" name="check-button" size="lg" switch>
                  {{ $t('pos_settings.enable_auto_adjustment') }}
                </b-form-checkbox>
              </div>
              <div class="col-lg-6 mb-5">
                <b-form-checkbox v-model="data.is_allow_skip_close_cash" name="check-button" size="lg" switch>
                  {{ $t('pos_settings.is_allow_skip_close_cash') }}
                </b-form-checkbox>
              </div>
              <div class="col-lg-6 mb-5">
                <b-form-checkbox v-model="data.is_foreign_currency" name="check-button" size="lg" switch>
                  {{ $t('pos_settings.is_foreign_currency') }}
                </b-form-checkbox>
              </div>
            </div>
          </div>

          <b-card class="mt-5" no-body>
            <b-card-header class="p-1" header-tab="header" role="tab">
              <b-button v-b-toggle.accordion-50 block class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon"
                        variant="link">
                {{ $t('accounts') }}
              </b-button>
            </b-card-header>

            <b-collapse id="accordion-50" accordion="my-accordion" role="tabpanel">
              <b-card-body class="row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('pos_settings.profit_account') }}</label>
                  <treeselect
                      :class="validation && validation.profit_account_id ? 'is-invalid' : ''"
                      :load-options="loadOptions"
                      :multiple="false"
                      :no-children-text="$t('No_sub_options')"
                      :no-options-text="$t('No_options_available')"
                      :no-results-text="$t('No_results_found')"
                      :options="accounts_list"
                      :placeholder="$t('Select')"
                      :searchable="true"
                      :show-count="true"
                      :value="data.profit_account_id"
                      @input="updateValueProfit">
                  </treeselect>
                  <span v-if="validation && validation.profit_account_id"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.profit_account_id[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('pos_settings.loss_account') }}</label>
                  <treeselect
                      :class="validation && validation.loss_account_id ? 'is-invalid' : ''"
                      :load-options="loadOptions"
                      :multiple="false"
                      :no-children-text="$t('No_sub_options')"
                      :no-options-text="$t('No_options_available')"
                      :no-results-text="$t('No_results_found')"
                      :options="accounts_list"
                      :placeholder="$t('Select')"
                      :searchable="true"
                      :show-count="true"
                      :value="data.loss_account_id"
                      @input="updateValueLoss">
                  </treeselect>
                  <span v-if="validation && validation.loss_account_id"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.loss_account_id[0] }}
                  </span>
                </div>

              </b-card-body>
            </b-collapse>
          </b-card>


        </div>
      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button class="btn btn-primary mr-2" type="button" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "from-pos-settings",
  data() {
    return {
      mainRoute: 'pos/pos-settings',
      mainRouteDependency: 'base/dependency',

      categories: [],
      clients: [],
      client_layout_list: [],
      enabled_payment_list: [],
      default_payment_list: [],
      allowed_categories_list: [],
      inventories: [],
      profit_account_list: [
        {id: 1, title: 'profit account 1'},
        {id: 2, title: 'profit account 2'},
        {id: 3, title: 'profit account 3'},
      ],
      accounts_list: [],

      idEdit: this.$route.params.id ? this.$route.params.id : null,

      data: {
        client_id: null,
        client_layout: 1,
        enabled_payment: 1,
        default_payment: 1,
        allowed_categories: 1,
        category_ids: null,
        enable_num_pad: false,
        show_product_images: false,
        accounting_System_per_invoice: false,
        enable_auto_adjustment: false,
        is_allow_skip_close_cash: false,
        is_foreign_currency: false,
        profit_account_id: null,
        loss_account_id: null,
        inventory_id: null,
        bank_id: null,
        treasury_cash_id: null,
        treasury_card_id: null,
        currency_id: null,
      },
      isEditing: false,
      validation: null,
      inventory: null,
      client: null,
      treasuries: [],
      treasury_cash: null,
      treasury_card: null,
      banks: [],
      bank: null,
      currencies: [],
    };
  },
  watch: {
    inventory: function (val) {
      if (val) {
        this.data.inventory_id = val.id;
      } else {
        this.data.inventory_id = null;
      }

    },
    client: function (val) {
      if (val) {
        this.data.client_id = val.id;
      } else {
        this.data.client_id = null;
      }

    },
    treasury_card: function (val) {
      if (val && val.id) {
        this.data.treasury_card_id = val.id;
      } else {
        this.data.treasury_card_id = null;
      }
    },
    treasury_cash: function (val) {
      if (val && val.id) {
        this.data.treasury_cash_id = val.id;
      } else {
        this.data.treasury_cash_id = null;
      }
    },
    bank: function (val) {
      if (val && val.id) {
        this.data.bank_id = val.id;
      } else {
        this.data.bank_id = null;
      }
    },
  },
  methods: {
    save() {
      // if (this.isEditing) {
      //     this.update();
      // } else {
      this.create();
      // }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        Vue.prototype.$postStatus = true;
      }).catch((error) => {
        Vue.prototype.$postStatus = true;
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    // update() {
    //     ApiService.put(this.mainRoute + '/' + this.idEdit, {
    //         ...this.data,
    //     }).then((response) => {
    //         this.validation = null;
    //         this.$successAlert(response.data.message);
    //     }).catch((error) => {
    //         this.$errorAlert(error);
    //         this.validation = error.response ? error.response.data.errors : null;
    //     });
    // },

    getData() {
      ApiService.get(this.mainRoute).then((response) => {
        if (response.data.data && response.data.data.id) {
          this.isEditing = true;
          this.data.client_id = response.data.data.client_id;
          this.data.inventory_id = response.data.data.inventory_id;
          this.data.client_layout = response.data.data.client_layout;
          this.data.enabled_payment = response.data.data.enabled_payment;
          this.data.default_payment = response.data.data.default_payment;
          this.data.allowed_categories = response.data.data.allowed_categories;
          this.data.category_ids = response.data.data.category_ids;
          this.data.enable_num_pad = response.data.data.enable_num_pad;
          this.data.show_product_images = response.data.data.show_product_images;
          this.data.accounting_System_per_invoice = response.data.data.accounting_System_per_invoice;
          this.data.enable_auto_adjustment = response.data.data.enable_auto_adjustment;
          this.data.is_allow_skip_close_cash = response.data.data.is_allow_skip_close_cash;
          this.data.is_foreign_currency = response.data.data.is_foreign_currency;
          this.data.profit_account_id = response.data.data.profit_account_id;
          this.data.loss_account_id = response.data.data.loss_account_id;
          this.inventory = response.data.data.inventory;
          this.client = response.data.data.client;

          this.data.bank_id = response.data.data.bank_id;
          this.data.treasury_cash_id = response.data.data.treasury_cash_id;
          this.data.treasury_card_id = response.data.data.treasury_card_id;
          this.data.currency_id = response.data.data.currency_id;
          this.treasury_cash = response.data.data.treasury_cash;
          this.treasury_card = response.data.data.treasury_card;
          this.bank = response.data.data.bank;
        }
      });
    },

    getCategories() {
      ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
        this.categories = response.data.data;
      });
    },

    getClients() {
      ApiService.get(this.mainRouteDependency + "/pos_default_customers").then((response) => {
        this.clients = response.data.data;
      });
    },

    getClientLayout() {
      ApiService.get(this.mainRouteDependency + "/client_layout").then((response) => {
        this.client_layout_list = response.data.data;
      });
    },

    getEnabledPayment() {
      ApiService.get(this.mainRouteDependency + "/enabled_payment").then((response) => {
        this.enabled_payment_list = response.data.data;
      });
    },


    getDefaultPayment() {
      ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
        this.default_payment_list = response.data.data;
      });
    },


    // getDefaultPayment() {
    //   ApiService.get(this.mainRouteDependency + "/default_payment").then((response) => {
    //     this.default_payment_list = response.data.data;
    //   });
    // },

    getAllowedCategories() {
      ApiService.get(this.mainRouteDependency + "/allowed_categories").then((response) => {
        this.allowed_categories_list = response.data.data;
      });
    },
    getAccountParents() {
      ApiService.get(`${this.mainRouteDependency}/${'account_parents'}`).then((response) => {
        this.accounts_list = response.data.data;
      });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },

    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getTreasuries() {
      ApiService.get(`${this.mainRouteDependency}/treasuries`).then((response) => {
        this.treasuries = response.data.data;
      });
    },
    getBanks() {
      ApiService.get(`${this.mainRouteDependency}/banks`).then((response) => {
        this.banks = response.data.data;
      });

    },
    // getBanks(filter) {
    //   if(filter && filter.length >= 3){
    //     ApiService.get(`${this.mainRouteDependency}/banks`,{params:{filter: filter}}).then((response) => {
    //       this.banks = response.data.data;
    //     });
    //   }else{
    //     this.banks = [];
    //   }
    // },

    // getProfitAccount() {
    //     ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
    //         this.profit_account_list = response.data.data;
    //     });
    // },
    // getLossAccount() {
    //     ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
    //         this.loss_account_list = response.data.data;
    //     });
    // },
    loadOptions() {
    },

    updateValue(value) {
      this.data.category_ids = value
    },

    updateValueProfit(value) {
      this.data.profit_account_id = value

    },
    updateValueLoss(value) {
      this.data.loss_account_id = value

    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.pos_settings")}]);
    this.getCategories();
    this.getClients();
    this.getClientLayout();
    this.getEnabledPayment();
    this.getDefaultPayment();
    this.getAllowedCategories();
    this.getAccountParents();
    this.getInventories();
    this.getCurrencies();
    this.getTreasuries();
    this.getBanks();

    this.getData();

    // if (this.idEdit) {
    //     this.getData();
    // }

  },
};
</script>


